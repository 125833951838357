<template>
    <b-modal
      id="modal-select2"
      title="Information du forfait"
      ok-title="Enregistrer"
      ref="packageInfoModal"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @close="resetMediaId()"
      @cancel="resetMediaId()"
      @ok.prevent='submitPackageInfo'
      no-close-on-backdrop
    >
      <package-info-form :current-package="currentPackage" :transportOptions="transportOptions" :pc-voyage-enable=pcVoyageEnable :accommodationOptions="accommodationOptions"  ref="create-package-info"></package-info-form>
    </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BImg

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PackageInfoForm from '@/views/groups/group/GroupPackages/PackageForms/PackageInfoForm'

export default {
  components: {
    BImg,
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BFormDatepicker,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadio,
    PackageInfoForm
    },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    transportOptions: {
      type: Array
    },
    accommodationOptions: {
      type: Array
    },
    currentPackage: {
      type: Object
    },
    pcVoyageEnable: {
      type: Boolean
    }
  },
  methods:{
    resetMediaId() {
      if(this.$refs['create-package-info'].option.media_id) {
        this.$refs['create-package-info'].option.media_id = null
      }
    },
    submitPackageInfo ()  {
      this.$refs['create-package-info'].$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.currentPackage.name){
            this.$emit('submitEditPackageInfo', this.$refs['create-package-info'].option)
          }else
          {
            this.$emit('submitPackageInfo', this.$refs['create-package-info'].option)
          }
        }
      })
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
