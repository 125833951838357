<template>
  <b-form>

    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="6">

            <h1 class="section-label mx-0 mb-2">
              Paramètre des tarifs par occupation
            </h1>
          </b-col>
          <b-col md="12">
            <b-tabs>
              <b-tab v-for="occupation in currentPackage.occupation_types" :key="occupation.type">
                <template #title>
                  <span>{{occupation.type}}</span>
                </template>

                <div v-if="occupation.occupation_type_pricing.length>0">
                  <b-row v-if="currentPackage.accept_children">
                    <b-col md="3" v-for="(pricing ,index) in occupation.occupation_type_pricing" :key="index">
                      <b-form-group
                        :label="`${getAgeCategory(pricing.type)} #${index+1}`"
                        :label-for="`${getAgeCategory(pricing.type)} #${index+1}`"
                      >
                        <b-form-input
                          id="basicInput"
                          placeholder="Entrer prix"
                          v-model="pricing.price"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col md="3" v-for="(pricing ,index) in occupation.occupation_type_pricing" :key="index">
                      <b-form-group
                        :label="`Adulte #${index+1}`"
                        :label-for="`Adulte #${index+1}`"
                      >
                        <b-form-input
                          id="basicInput"
                          placeholder="Entrer prix"
                          v-model="pricing.price"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>


      </b-col>
    </b-row>
  </b-form>
</template>

<script>
  import BCardCode from "@core/components/b-card-code";
  import {
    BButton,
    BCardText,
    BCol,
    BForm, BFormCheckbox,
    BFormDatepicker, BFormFile,
    BFormGroup,
    BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupPrepend,
    BModal,
    BRow, BTab, BTabs, VBModal
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import Ripple from "vue-ripple-directive";
  import groupCreationConfig from "@core/data/groupCreationConfig";

  export default {
    name: "PackagePricesForm",
    components: {
      BCardCode,
      BButton,
      BModal,
      BForm,
      BFormInput,
      BCardText,
      BFormGroup,
      vSelect,
      BFormDatepicker,
      BRow,
      BCol,
      BInputGroup,
      BFormCheckbox,
      BInputGroupPrepend,
      BFormTextarea,
      BTabs,
      BFormFile,
      BTab,
      BFormInvalidFeedback,
      ToastificationContent,
    },
    directives: {
      Ripple,
    },
    props: {
      currentPackage: {
        type: Object
      },
      actionType: {
        type: String
      }
    },
    data() {
      return {
        hasGuide: false,
        selected: 'USA',
        option: ['USA', 'Canada', 'Maxico'],
        groupCreationConfig,
        pkg: {
          occupation_types: ['single', 'double'],
          rules: {
            single: {
              adult_min: 1,
              travelers_max: 1,
            },
            double: {
              adult_min: 2,
              travelers_max: 4
            }
          },
          pricing: {
            single: {
              adults: [1234]
            },
            double: {
              adults: [1234, 444]
            }
          },
          are_children_accepted: true
        },
        option_index: 0,
      }
    },
    created() {
    this.checkIfContainsPricing();
    },
    methods: {
      checkIfContainsPricing() {
        if(this.currentPackage.occupation_types.length>0){
          let emptyOccupationTypePricing=[];
          this.currentPackage.occupation_types.forEach((element,index)=>{
            if (element.occupation_type_pricing.length===0){
              emptyOccupationTypePricing.push({occup_type:element,position:index});
            }
          })
          if (emptyOccupationTypePricing.length>0){
            for (let i=0;i<emptyOccupationTypePricing.length;i++){
              this.formatEmptyPricing(emptyOccupationTypePricing[i].position)
            }
          }else
          {
            this.currentPackage.occupation_types.forEach((element,index)=>{
              this.formatFilledPricing(index)
            })

          }
        }
      },
      submitPackagePricing(event) {
        this.$emit('submitPackagePricing', this.currentPackage)
      },
      addAccommodationToPackage(pkg) {
        pkg.room_ids.push({
          accommodation: null,
          checkin: null,
          checkout: null
        })
      },
      removeItem(optionIndex, deleteIndex) {
        this.option[optionIndex].room_ids.splice(deleteIndex, 1)
      },
      occupationLabel(occupation) {
        let labels = {
          single: 'Simple',
          double: 'Double',
          triple: 'Triple',
          quadruple: 'Quadruple'
        }

        return labels[occupation];
      },
      getMaxAdults(occupation) {
        try {
          return parseInt(occupation.adult_min)
        } catch {
          return 0;
        }
      },
      getChildrenCategories() {
        try {
          return occupation.children_categories
        } catch {
          return [];
        }
      },
      getMaxTravelers() {
        try {
          return occupation.travelers_max
        } catch {
          return 0;
        }
      },
      getAgeCategory(type)
      {
        return this.groupCreationConfig.ageCategories.find(category => category.value === type).text
      },
      formatEmptyPricing(element) {
        const ADULT = 'ADULT'
        this.currentPackage.occupation_types[element].occupation_type_pricing = []
        let adult_index = 0;
        if (this.currentPackage.occupation_types[element].supported_children_types.length>0){
          while (adult_index < this.currentPackage.occupation_types[element].adult_min) {
            this.currentPackage.occupation_types[element].occupation_type_pricing.push({
              "index": adult_index,
              "price": 0,
              "type": ADULT
            })
            adult_index++;
          }
          this.currentPackage.occupation_types[element].supported_children_types.forEach(children_category => {
            let children_cat_index = 0;
            let CHILDREN_TYPE = children_category.toUpperCase();
            let NB_OF_CHILDREN = (parseInt(this.currentPackage.occupation_types[element].travelers_max) - parseInt(this.currentPackage.occupation_types[element].adult_min))
            while (children_cat_index < NB_OF_CHILDREN) {
              this.currentPackage.occupation_types[element].occupation_type_pricing.push({
                "index": children_cat_index,
                "price": 0,
                "type": CHILDREN_TYPE
              })
              children_cat_index++;
            }
          })
        }else {
          while (adult_index < this.currentPackage.occupation_types[element].travelers_max) {
            this.currentPackage.occupation_types[element].occupation_type_pricing.push({
              "index": adult_index,
              "price": 0,
              "type": ADULT
            })
            adult_index++;
          }
        }
      },
      formatFilledPricing(element) {
        let tempOccupation_type_pricing_Array=this.currentPackage.occupation_types[element].occupation_type_pricing;
        let tempOccupation_type_pricing_Child_Array=[];

        this.currentPackage.occupation_types[element].occupation_type_pricing.forEach((el)=>{
          if (el.type!=='ADULT'){
            tempOccupation_type_pricing_Child_Array.push(el);
          }
        })
        this.currentPackage.occupation_types[element].occupation_type_pricing = [];
        const ADULT = 'ADULT'
        let adult_index = 0;
        if (this.currentPackage.occupation_types[element].supported_children_types.length>0){
          while (adult_index < this.currentPackage.occupation_types[element].adult_min) {
            this.currentPackage.occupation_types[element].occupation_type_pricing.push({
              "index": adult_index,
              "price": tempOccupation_type_pricing_Array[adult_index]!==undefined?tempOccupation_type_pricing_Array[adult_index].price:0,
              "type": ADULT
            })
            adult_index++;
          }
          let NB_ARRAY_CHILDREN=tempOccupation_type_pricing_Child_Array.length;
          let NB_OF_CHILDREN = (parseInt(this.currentPackage.occupation_types[element].travelers_max) - parseInt(this.currentPackage.occupation_types[element].adult_min))
          let NB_CHILDREN_TYPE =this.currentPackage.occupation_types[element].supported_children_types.length;
          if (NB_OF_CHILDREN*NB_CHILDREN_TYPE===NB_ARRAY_CHILDREN){
            for (let i=0;i<NB_ARRAY_CHILDREN;i++){
              this.currentPackage.occupation_types[element].occupation_type_pricing.push(tempOccupation_type_pricing_Child_Array[i]);
            }
          }else {
            this.currentPackage.occupation_types[element].supported_children_types.forEach(children_category => {
              let children_cat_index = 0;
              let CHILDREN_TYPE=children_category;
              let filteredArrayChildFilled=tempOccupation_type_pricing_Child_Array.filter(function (el) {
                return el.type===CHILDREN_TYPE;
              });
              while (children_cat_index < NB_OF_CHILDREN) {
                this.currentPackage.occupation_types[element].occupation_type_pricing.push({
                  "index": children_cat_index,
                  "price":  filteredArrayChildFilled[children_cat_index]!==undefined?filteredArrayChildFilled[children_cat_index].price:0,
                  "type": CHILDREN_TYPE
                })
                children_cat_index++;
              }
            })
          }
        }else {
          while (adult_index < this.currentPackage.occupation_types[element].travelers_max) {
            this.currentPackage.occupation_types[element].occupation_type_pricing.push({
              "index": adult_index,
              "price":  tempOccupation_type_pricing_Array[adult_index]!==undefined?tempOccupation_type_pricing_Array[adult_index].price:0,
              "type": ADULT
            })
            adult_index++;
          }
        }

      },
    },
    computed: {
      getOccupationType() {
        try {
          return this.currentPackage.occupation_types
        } catch {
          return []
        }
      }
    },

  }
</script>

<style scoped>

</style>
