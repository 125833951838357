<template>
  <b-modal
    id="modal-select2"
    title="Prix du forfait "
    ok-title="Enregistrer"
    cancel-variant="outline-secondary"
    size="lg"
    @ok='submitPackagePricing'
    no-close-on-backdrop
  >
    <package-prices-form :currentPackage="currentPackage" :actionType="actionType" ref="prices-form"></package-prices-form>
  </b-modal>
</template>

<script>
  import BCardCode from '@core/components/b-card-code'
  import {
    BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormDatepicker,

    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import PackagePricesForm from '@/views/groups/group/GroupPackages/PackageForms/PackagePricesForm'

  export default {
    components: {
      BCardCode,
      BButton,
      BModal,
      BForm,
      BFormInput,
      BCardText,
      BFormGroup,
      vSelect,
      BFormDatepicker,
      BRow,
      BCol,
      BInputGroup,
      BFormCheckbox,
      BInputGroupPrepend,
      BFormTextarea,
      BTabs,
      BFormFile,
      BTab,
      BFormInvalidFeedback,
      ToastificationContent,
      PackagePricesForm
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    props: {
      currentPackage: {
        type: Object
      },
      actionType: {
        type: String
      }
    },
    methods:{
      submitPackagePricing(){
        this.$emit('submitPackagePricing',this.currentPackage)
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
