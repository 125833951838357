<template>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >

        <div v-if="!hasDetails" class="mt-4">
          <b-card  title="Étape 1" header-tag="header" body-class="text-center" class="mx-auto" style="max-width: 80%; min-width: 60%">

            <b-card-text>
              <p>
                Ajouter les informations générales relatives à votre forfait.
              </p>
              <p>
                Associer une option de transport avec une ou des options d'hébergement.
              </p>
              <p>
                Indiquer le ou les types d'occupation offerts dans ce forfait.
              </p>

            </b-card-text>

            <b-button variant="primary"  @click="showPackageInfoModal">Ajouter</b-button>
          </b-card>
        </div>
        <b-card v-if="hasDetails"  header-tag="header" class="mt-2" >

          <template #header>
              <h3> Information du forfait </h3>
              <b-button variant="outline-primary" @click="showPackageInfoModal">Modifier</b-button>
          </template>
          <b-row class="mb-2">
          <b-col md="3">
            <b-carousel
              img-width="150"
              img-height="150"
              controls
              indicators

            >
              <b-carousel-slide :img-src="currentPackage.image_url || require('@/assets/images/group/stock_plane_2.jpeg')">
              </b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col md="9">
          <b-row>
            <b-col cols="4">
              <div>
                <h5 class="mb-75">
                  Nom du forfait
                </h5>
                <b-card-text>
                  {{currentPackage.name }}
                </b-card-text>
              </div>
            </b-col>

            <b-col cols="4">
              <div>
                <h5 class="mb-75">
                  Option de transport associé au forfait
                </h5>
                <b-card-text>
                  {{currentPackage.transportation ? currentPackage.transportation.name : 'N/A'}}
                </b-card-text>
              </div>
            </b-col>

            <b-col cols="4">
              <div>
                <h5 class="mb-75">
                  Enfants acceptés dans le groupe
                </h5>
                <b-card-text v-if="currentPackage.accept_children">
                  {{booleanLabel(currentPackage.accept_children)}}
                </b-card-text>
                <b-card-text v-else >
                  Non
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="4">
              <div>
                <h5 class="mb-75">
                  Type d'occupation offert
                </h5>
                <b-card-text>
                  <ul style="list-style: none; padding-inline-start: 2px">
                    <li v-for="(occupation) in currentPackage.occupation_types" :key="occupation.type">

                      <span class="bullet bullet-sm mr-1 bullet-success"></span>
                      <small>{{occupation.type}}</small>
                    </li>
                  </ul>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <h5 class="mb-75">
                  Hébergement associé au forfait
                </h5>
                <b-card-text>
                  <ul style="list-style: none; padding-inline-start: 2px">
                    <li v-for="(room) in currentPackage.rooms" :key="room.id">

                      <span class="bullet bullet-sm mr-1 bullet-primary"></span>
                      <small>{{room.room_category}}</small>
                    </li>
                  </ul>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="4" v-if="tenantConfig.pcVoyageEnable">
              <div>
                <h5 class="mb-75">
                  Numéro de référence
                </h5>
                <b-card-text>
                  {{currentPackage.reference_number }}
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">  
            <b-col cols="4" v-if="tenantConfig.publicUrlEnabled">
              <div>
                <h5 class="mb-75">
                  Affichage du forfait
                </h5>
                <b-card-text>
                  <span v-if="currentPackage.is_public">
                    <feather-icon icon="EyeIcon" class="text-success"/>
                    Ce forfait est visible pour les clients externes
                  </span>
                  <span v-else>
                    <feather-icon icon="EyeIcon" class="text-grey" />
                    Ce forfait ne sera pas affiché pour les clients externes
                  </span>
                  </b-card-text>
              </div>
            </b-col>
          </b-row>
          </b-col>

          </b-row>
          <!--
          <b-row>
            <b-col md="6">
              <div>
                <h5 class="mb-75">
                  Description
                </h5>
                <b-card-text v-if="currentPackage.description">
                  {{currentPackage.description}}
                </b-card-text>
                <b-card-text v-else>
                  <i>Aucune description</i>
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          -->

          <div class="text-right">
            <b-button variant="primary" class="ml-1" @click="$emit('next-tab')">Suivant</b-button>
          </div>

        </b-card>
        <group-package-info-modal ref='package-info-modal' :currentPackage="currentPackage" :transport-options="transportOptions" :accommodation-options="accommodationOptions" :pc-voyage-enable=tenantConfig.pcVoyageEnable @submitPackageInfo="submitPackageInfo" @submitEditPackageInfo="submitEditPackageInfo" />
      </b-col>
    </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BIconFileEarmarkEasel, BCarousel, BCarouselSlide  } from 'bootstrap-vue'
import GroupPackageInfoModal from '@/views/groups/group/GroupPackages/PackageModals/GroupPackageInfoModal'
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import {reactive, ref, computed} from "@vue/composition-api";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BCarousel, BCarouselSlide,
    GroupPackageInfoModal

  },
  props: {
    transportOptions: {
      type: Array
    },
    accommodationOptions: {
      type: Array
    },
    currentPackage: {
      type: Object
    },
    currentPackageId: {
      type: String
    },
    actionType: {
      type: String
    }
  },
  data() {
    return {
      hasPackageInfo: false,
    }
  },
  setup() {
    let tenantConfig = reactive({
      pcVoyageEnable: false,
      publicUrlEnabled: false,
    });

    try {
      tenantConfig.pcVoyageEnable = store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;
      tenantConfig.publicUrlEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.public_url_enable;
    } catch (e) {
      console.error(e)
    }
  
  return {
    tenantConfig
  }
  },
  created: function () {
    try {
      if (this.currentPackage && this.currentPackage.id) {
        this.hasPackageInfo = true;
      }
    } catch (error) {
    }

  },
  watch: {
    currentPackage: function(newVal, oldVal) { // watch it
      this.hasPackageInfo = true;

    }
  },
  methods: {
    showAddPackageModal() {
      this.$refs['add-package-modal'].$children[0].show()
    },
    showEditPackageModal() {
      this.$refs['edit-package-modal'].$children[0].show()
    },
    showPackageInfoModal() {
      this.$refs['package-info-modal'].$children[0].show()
    },
    submitPackageInfo(packageInfo) {
      this.hasPackageInfo = true;
      this.currentPackage = this.standardizePackageInfo(packageInfo);
      this.$emit('submitPackageInfo', packageInfo)

    },
    submitEditPackageInfo(packageInfo) {
      this.hasPackageInfo = true;
      this.currentPackage = this.standardizePackageInfo(packageInfo);
      this.$emit('submitUpdatePackageInfo', packageInfo)

    },
    getPackageAcceptChildrenValue(value) {
      return value === 'true'
    },
    standardizePackageInfo(packageInfo) {
      let occupation_types = [];

      packageInfo.occupation_types.forEach(type => {
        occupation_types.push({
          type: type,
        })
      })

      let currentPkg = {
        name: packageInfo.name,
        is_public: packageInfo.is_public,
        reference_number: packageInfo.reference_number,
        description: packageInfo.description,
        are_children_accepted: this.getPackageAcceptChildrenValue(packageInfo.are_children_accepted),
        occupation_types: occupation_types,
        transportation: packageInfo.transportation
      }
      return currentPkg;
    },
    booleanLabel(value)  {
      let labels = {
        false: "Non",
        true: "Oui"
      }
      return labels[value]
    }
  },
  computed: {
    hasDetails() {
      try{
        if (this.currentPackage.name || this.currentPackage.id) {
          return true;
        } else {
          return false;
        }
      } catch {
        return false
      }

    }
  }

}
</script>

<style>

</style>
