<template>
  <b-modal
    id="modal-select2"
    title="Règles du forfait"
    ok-title="Enregistrer"
    cancel-title="Annuler"
    cancel-variant="outline-secondary"
    size="lg"
    ref="packageRulesModal"
    @ok.prevent='submitPackageRules'
    no-close-on-backdrop
  >
    <package-rules-form :currentPackage="currentPackage" ref="create-package-rules"></package-rules-form>
  </b-modal>
</template>

<script>
  import BCardCode from '@core/components/b-card-code'
  import {
    BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import PackageRulesForm from '@/views/groups/group/GroupPackages/PackageForms/PackageRulesForm'
  export default {
    components: {
      BCardCode,
      BButton,
      BModal,
      BForm,
      BFormInput,
      BCardText,
      BFormGroup,
      vSelect,
      BFormDatepicker,
      BRow,
      BCol,
      BInputGroup,
      BFormCheckbox,
      BFormCheckboxGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BTabs,
      BFormFile,
      BTab,
      BFormInvalidFeedback,
      ToastificationContent,
      PackageRulesForm
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    props: {
      currentPackage: {
        type: Object
      }
    },
    methods: {
      submitPackageRules () {
        this.$refs['create-package-rules'].$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$emit('submitPackageRules', this.currentPackage)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:"Vous devez vérifier les autres types d'occupations",
                icon: 'EditIcon',
                variant:'danger',
              },
            })
          }
        })

      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
